<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div
    :id="version.version"
  >
    <header class="controls mb-small">
      <h2>Alta Call {{ version.version }}</h2>
      <time class="discrete" :title="version.date" :datetime="version.date">{{ prettyDatetime(version.date) }}</time>
    </header>
    <table>
      <thead>
        <th>
          <translate translate-context="*/*/*">Operating System / Architecture</translate>
        </th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="url in version.urls" :key="url">
          <td>{{ url.name }}</td>
          <td>
            <a :href="url.url">
              <app-icon name="download"></app-icon>
              <translate translate-context="*/*/*/Verb">
                Download          
              </translate>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="mt-regular" v-if="showChanges">
      <strong>
        <translate translate-context="*/*/*/Name">Changes:</translate>
      </strong>
      <ul>
        <li v-for="row in version.changelog" :key="row">{{ row }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {prettyDatetime} from '@/time'

export default {
  props: {
    version: {required: true},
    showChanges: {default: true},
  },
  data () {
    return {
      prettyDatetime
    }
  }
}
</script>